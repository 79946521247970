import React from "react";
import styles from "./Modal.module.css";
const ModalComponent = ({
  children,
  title,
  close,
  onAccept,
  onReject,
  AcceptText = "Submit",
  RejectText = "Cancel",
  size = "md",
  form,
  loading = false,
  initialLoading = false,

  renderButtons = (onAccept, onReject) => (
    <>
      {typeof onReject === "function" && (
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={onReject || close}
        >
          {RejectText}
        </button>
      )}
      {typeof onAccept === "function" && (
        <button
          type="submit"
          className="btn btn-sm btn-success"
          onClick={onAccept}
          form={form}
          disabled={loading}
        >
          {AcceptText}
          {loading && (
            <>
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </>
          )}
        </button>
      )}
    </>
  ),
}) => {
  return (
    <div className="modal d-block">
      <div className={`modal-dialog modal-dialog-centered modal-${size}`}>
        <div className={`modal-content ${styles["content"]}`}>
          <button
            type="button"
            className={styles["close-button"]}
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {title && (
            <div className="modal-header">
              <h5
                className={`${styles["title"]} modal-title`}
                id="exampleModalLongTitle"
              >
                {title}
              </h5>
            </div>
          )}

          <div className={`${styles["modal__body"]} modal-body`}>
            {children}
          </div>
          {!initialLoading && (
            <div className="modal-footer">
              {renderButtons(onAccept, onReject)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
