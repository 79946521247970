import { useState, createContext, useContext, useEffect } from "react";
import {useNavigate} from 'react-router-dom'
// import useAuth from "hooks/useAuth";

// set auth session
const setSession = (token) => {
  if (token) {
    sessionStorage.setItem("loginData", token);
  } else {
    sessionStorage.clear();
  }
};

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate()
  const [authState, setAuthState] = useState({
    user: null,
    isAuthenticated: false,
  });
  const [loading, setLoading] = useState(true);
 

  const initUser = () => {
    let sessionDetails = JSON.parse(sessionStorage.getItem("loginData"));
   

    if (sessionDetails?.token) {
      setSession(sessionDetails?.token);
      setAuthState((prevState) => ({
        ...prevState,
        user: sessionDetails,
        isAuthenticated: true,
      }));
      setLoading(false);

      // client
      //   .get("auth/me")
      //   .then(({ data }) => {
      //     setAuthState((prevState) => ({
      //       ...prevState,
      //       user: data,
      //       isAuthenticated: true,
      //     }));
      //   })
      //   .catch(console.log)
      //   .finally(() => {
      //     setLoading(false);
      //   });
    } else {
      setLoading(false);
      setAuthState((prevState) => ({
        ...prevState,
        user: null,
        isAuthenticated: false,
      }));
     
    }
  };

  useEffect(() => {
    // initUser();
    // eslint-disable-next-line
  }, []);

  const logout = () => {
    
    setAuthState((prevState) => ({
      ...prevState,
      user: null,
      isAuthenticated: false,
    }));
    setSession(null);
    let currentPath = window.location.pathname?.split("/")?.pop();
    if(currentPath === "mkopa"|| currentPath === "buy-phone") {
      if(currentPath === "buy-phone"){
        navigate("/ba-login")
      }
      if(currentPath === "mkopa"){
        navigate("/mkopa-login")
      }
    }else{
      navigate("/")
    }
   
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

// const AuthConsumer = AuthContext.Consumer;
// export { AuthContextProvider, AuthConsumer };
