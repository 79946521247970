import React from "react";
import styles from "./Button.module.css";

const Button = ({
  onClick = () => {},
  icon = "feather-plus",
  label = "button label",
}) => {
  return (
    <button
      className={`${styles["card-button"]} ${styles["button"]}`}
      onClick={() => onClick()}
    >
      <svg
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        className={`feather ${icon}`}
      >
        <path d="M12 5v14M5 12h14" />
      </svg>
      {label}
    </button>
  );
};

export default Button;
