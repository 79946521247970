import React, { useState } from "react";

import { Layout } from "antd";
import { Route, Routes } from "react-router-dom";
import ModalRoot from "components/elements/modal/ModalRoot";

import ListMenu from "./Menu";
import NavBar from "./Navbar";
import SideBar from "./Sidebar";

function Dashboard({
  sidebarLinks = [],
  routes = [],
  name = "Dashboard",
  country,
}) {
  const [contentIndex, setContentIndex] = useState(0);
  const [selectedKey, setSelectedKey] = useState("0");
  const [isOpen, setIsOpen] = useState(true);
  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);
  };
  const Menu = (
    <ListMenu
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
      sidebarLinks={sidebarLinks}
      country={country}
    />
  );
  const handleTrigger = () => setIsOpen(!isOpen);

  return (
    <div>
      <ModalRoot />
      <NavBar menu={Menu} onToggleClick={handleTrigger} name={name} />
      <Layout>
        {sidebarLinks.length !== 0 && (
          <SideBar menu={Menu} isSideBarOpen={isOpen} />
        )}
        <Layout.Content>
          <Routes>
            {routes.map((item, i) => (
              <Route
                key={i}
                exact
                path={item.path}
                element={
                  <React.Suspense fallback={<p>Loading...</p>}>
                    {item.element}
                  </React.Suspense>
                }
              />
            ))}
          </Routes>
        </Layout.Content>
      </Layout>
    </div>
  );
}

export default Dashboard;
