import React from "react";
import { SalesAgentRoutes } from "app/sales-agent/salesAgentRoutes";
import { salesAgentLinks } from "app/sales-agent/modules/general/landing/salesAgentLinks";
import { Dashboard } from "layouts";
const SalesAgentDashboard = ({ country }) => {
  return (
    <Dashboard
      routes={SalesAgentRoutes}
      name="Sales Agent Dashboard"
      sidebarLinks={salesAgentLinks}
      country={country}
    />
  );
};

export default SalesAgentDashboard;
