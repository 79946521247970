import React from "react";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function RequireAuth({ children, loggedIn }) {
  const location = useLocation();
  

  return loggedIn  ? (
    children
  ) : (
    <Navigate to="/" replace state={{ path: location.pathname }} />
  );
}

export default RequireAuth;
