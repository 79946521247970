import React, { useState, useContext, useEffect } from "react";
import styles from "./Search.module.css";
import { AppContext } from "context/AppContext";
import { Input } from "antd";

const Search = ({ data, placeholder = "Search...", searchBy = [] }) => {
  const { query, setQuery, setResults } = useContext(AppContext);

  const [searchParam] = useState(searchBy);

  const onSearch = () => {
    // let res = data.filter((item) => {
    //   return searchParam.some((newItem) => {
    //     return (
    //       item[newItem].toString().toLowerCase().indexOf(query.toLowerCase()) >
    //       -1
    //     );
    //   });
    // });
    // setResults(res);
    setResults(data);
  };

  useEffect(() => {
    onSearch();
  }, [query]);

  return (
    <div className={styles["search-wrapper"]}>
      <Input
        prefix={<i className="icofont icofont-search" />}
        placeholder={placeholder}
        allowClear
        bordered={false}
        onChange={(e) => setQuery(e.target.value)}
      />
    </div>
  );
};

export default Search;
