import React, { useState, createContext } from "react";
import initialData from "../components/elements/form/defaults.json";

const AppContext = createContext();
const AppContextProvider = (props) => {
  // ROLES
  const [userRole, setUserRole] = useState( );

  const [recalculateData, setRecalculateData] = useState({
    aboutDevice: {},
    phoneDetails: {},
    physicalDefects: [],
    functionalDefects: [],
    extras: [],
  });
  const [openDrawer, setOpenDrawer] = useState({
    drawer_one: false,
    drawer_two: false,
    drawer_three: false,
    drawer_four: false,
    drawer_five: false,
    drawer_six: false,
  });
  const [selectedItems, setSelectedItems] = useState(initialData);

  const [user, setUser] = useState({});

  // **************SEARCH************
  const [query, setQuery] = useState(""); //     ssearch query
  const [results, setResults] = useState([]); //result of the search

  // current step
  const [currentStep, setCurrentStep] = useState(0);

  // image upload url
  const [url, setNewUrl] = useState("");

  // recalculations
  const [recalculations, setRecalculations] = useState();

  return (
    <AppContext.Provider
      value={{
        recalculateData,
        setRecalculateData,
        openDrawer,
        setOpenDrawer,
        selectedItems,
        setSelectedItems,
        user,
        setUser,
        query,
        setQuery,
        results,
        setResults,
        userRole,
        setUserRole,
        currentStep,
        setCurrentStep,
        url,
        setNewUrl,
        recalculations,
        setRecalculations,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

const AppConsumer = AppContext.Consumer;
export { AppContextProvider, AppConsumer, AppContext };
