import React from "react";
import styled from "styled-components";
import styles from "layouts/Details.module.css";

const StyledPageTitle = styled.div`
  background: #fafafa;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DetailsView = ({ pageHeader, data, children }) => {
  return (
    <>
      {data && (
      <div className="container-fluid">
        <StyledPageTitle>{pageHeader}</StyledPageTitle>

        <div className={styles["lightbox-blanket"]}>
          <div className={styles["pop-up-container"]}>
            <div className={styles["pop-up-container-vertical"]}>
              <div className={styles["pop-up-wrapper"]}>
                <div className={styles["product-details"]}>{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default DetailsView;
