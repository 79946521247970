import React from "react";
import styled from "styled-components";

const StyledPageTitle = styled.div`
  background: #fafafa;
  padding: 1rem;
`;

const ListView = ({ pageHeader, table, data,extra }) => {
  return (
    <div className="">
      {data && (
        <div>
          {pageHeader &&<StyledPageTitle>{ pageHeader}</StyledPageTitle>}
            {extra && <>{ extra}</>}
          <div className="container-fluid" style={{ padding: "1rem" }}>
            {table &&<div className="pb-8 pt-3">{ table}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default ListView;
