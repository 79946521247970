import React, { useState } from "react";
import { Form, Select } from "antd";
import Modal from "components/elements/modal/Modal";
import { useHttp } from "hooks";
import { useToastContext, ADD } from "context/ToastContext";
import UserService from "services/UserService";

const { Option } = Select;

export default function ReasignModal(props) {
  const { toastDispatch } = useToastContext();
  const [form] = Form.useForm();
  const { error, loading, sendHttpRequest: assignQC } = useHttp();
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  const handleConfirmSubmit = (values) => {
    const data = {
      partnerId: props?.id,
      wareHouseId: selectedWarehouse, // selectedWarehouse is now used
      flag: "saleSupervisor",
      userId: UserService.getCurrentUserLoginData().id,
    };
    assignQC(`assign_sales_partner/${data.userId}`, "PUT", data, ({ msg }) => {
      props.close();
      props.refresh();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => form.submit()}
      onReject={props.close}
      title="Reasign Warehouse"
      form="makeSale"
      loading={loading}
      AcceptText={"Submit"}
    >
      <Form
        layout="vertical"
        id="makeSale"
        form={form}
        onFinish={handleConfirmSubmit}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Warehouse"
          name="warehouse"
          rules={[{ required: true, message: "Please select a warehouse" }]}
        >
          <Select
            placeholder="Select a Warehouse"
            onChange={setSelectedWarehouse}
          >
            {props?.wareHouseData?.map((warehouse) => (
              <Option key={warehouse.value} value={warehouse.value}>
                {warehouse.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
