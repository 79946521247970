// icon physical defects imports
import volume_buttons from "assets/images/icons/webIcons/webIcons-03.png";
import fingerprint_scanner from "assets/images/icons/webIcons/webIcons-04.png";
import wifi from "assets/images/icons/webIcons/webIcons-05.png";
import speakers from "assets/images/icons/webIcons/webIcons-12.png";
import power_button from "assets/images/icons/webIcons/webIcons-08.png";
import charging_port from "assets/images/icons/webIcons/webIcons-09.png";
import bluetooth from "assets/images/icons/webIcons/webIcons-14.png";
import phone_vibrator from "assets/images/icons/webIcons/webIcons-15.png";
import microphone from "assets/images/icons/webIcons/webIcons-16.png";
import infrared from "assets/images/icons/webIcons/webIcons_Infrared.svg";
import flashLight from "assets/images/icons/webIcons/webIcons_flashLight.svg";
import simcardTray from "assets/images/icons/webIcons/webIcons_simCardTray.svg";
import earpiece from "assets/images/icons/webIcons/webIcons_earpiece.svg";
import facesensor from "assets/images/icons/webIcons/webIcons-10.png";
import backglass from "assets/images/icons/webIcons/webIcons-48_backglass.png";

// icon functional defects imports
import scratched_screen from "assets/images/icons/webIcons/webIcons-26.png";
import spot_lines from "assets/images/icons/webIcons/webIcons-30.png";
import broken_screen from "assets/images/icons/webIcons/webIcons-24.png";
import camera_glass from "assets/images/icons/webIcons/webIcons-13.png";
import battery from "assets/images/icons/webIcons/webIcons-06.png";
import silentButton from "assets/images/icons/webIcons/webIcons_silentButton.svg";
import phoneDented from "assets/images/icons/webIcons/webIcons_phoneDented.svg";

// icon extras imports
import charger from "assets/images/icons/webIcons/webIcons-17.png";
import warranty from "assets/images/icons/webIcons/webIcons-30.png";
import receipt from "assets/images/icons/webIcons/webIcons-24.png";
import earphones from "assets/images/icons/webIcons/webIcons-18.png";
import stylus from "assets/images/icons/webIcons/webIcons_stylus.svg";
import cable from "assets/images/icons/webIcons/webIcons_cable.svg";

export const functional_defects = [
  {
    description: "Phone is able to switch on and off? Yes/No?",
    image: volume_buttons,
    name: "deviceOn",
    checked: false,
    disabled: false,
  },
  {
    description: "Phone is able to make or receive calls? Yes/No",
    shortInfo:"Try and make a quick call",
    image: volume_buttons,
    name: "workingPhone",
    checked: false,
    disabled: false,
  },
  {
    description: "Volume buttons working",
    image: volume_buttons,
    name: "volumeButtons",
    checked: false,
    disabled: false,
  },
  {
    description: "Is your battery health above 85%?",
    shortInfo:"To check your battery health, Go to Settings and view battery health.",
    image: volume_buttons,
    name: "batteryPercentage",
    checked: false,
    disabled: false,
  },
  {
    description: "Fingerprint scanner working",
    image: fingerprint_scanner,
    name: "fingerScanner",
    checked: false,
    disabled: false,
  },
  // {
  //   description: "Wifi working",
  //   image: wifi,
  //   name: "WiFi",
  //   checked: false,
  //   disabled: false,
  // },
  // {
  //   description: "Infrared working",
  //   image: infrared,
  //   name: "infrared",
  //   checked: false,
  //   disabled: false,
  // },
  {
    description: "SimCard Tray available",
    image: simcardTray,
    name: "simcardTray",
    checked: false,
    disabled: false,
  },
  //{
  //   description: "Flashlight working",
  //   image: flashLight,
  //   name: "flashlight",
  //   checked: false,
  //   disabled: false,
  // },
  // {
  //   description: "Face sensor working",
  //   image: facesensor,
  //   name: "facesensor",
  //   checked: false,
  //   disabled: false,
  // },
  {
    description: "Speakers working",
    shortInfo:"Try and play a song or a video on youtube",
    image: speakers,
    name: "speakers",
    checked: false,
    disabled: false,
  },
  {
    description: "Earpiece working",
    image: earpiece,
    name: "earpiece",
    checked: false,
    disabled: false,
  },
  {
    description: "Power button working",
    image: power_button,
    name: "powerButton",
    checked: false,
    disabled: false,
  },
  {
    description: "Charging port working",
    shortInfo:"Try and connect the phone to a power source",
    image: charging_port,
    name: "chargingPort",
    checked: false,
    disabled: false,
  },
  // {
  //   description: "Bluetooth working",
  //   image: bluetooth,
  //   name: "bluetooth",
  //   checked: false,
  //   disabled: false,
  // },
  // {
  //   description: "Phone vibrator working",
  //   image: phone_vibrator,
  //   name: "vibrator",
  //   checked: false,
  //   disabled: false,
  // },
  // {
  //   description: "Microphone working",
  //   image: microphone,
  //   name: "microphone",
  //   checked: false,
  //   disabled: false,
  // },
  {
    description: "Does your front camera work? Yes/No",
    image: microphone,
    name: "selfieCamera",
    checked: false,
    disabled: false,
  },
  {
    description: "Does your back camera work? Yes/No",
    image: microphone,
    name: "mainCamera",
    checked: false,
    disabled: false,
  },
];

export const physical_defects = [
  {
    description: "Screen is scratched",
    image: scratched_screen,
    name: "scratchedScreen",
    checked: false,
    disabled: false,
  },
  // {
  //   description: "Screen has spots or lines",
  //   image: spot_lines,
  //   name: "spotsOnScreen",
  //   checked: false,
  //   disabled: false,
  // },
  {
    description: "Is the screen broken, burnt or has spots?  Yes/No",
    shortInfo:"If the screen is discolored, looks pink or yellow - please mark yes",
    image: broken_screen,
    name: "brokenScreen",
    checked: false,
    disabled: false,
  },
  {
    description: "Camera glass broken",
    image: camera_glass,
    name: "cameraGlass",
    checked: false,
    disabled: false,
  },
  {
    description: "Back glass/cover broken",
    image: backglass,
    name: "backglass",
    checked: false,
    disabled: false,
  },
  // {
  //   description: "Battery has issues",
  //   image: battery,
  //   name: "battery",
  //   checked: false,
  //   disabled: false,
  // },
  {
    description: "Body has scratches",
    shortInfo:'If the phone body/screen has multiple & visible scratches, please mark yes',
    image: battery,
    name: "scratchedBody",
    checked: false,
    disabled: false,
  },
  {
    description: "Phone has dents",
    image: phoneDented,
    name: "dentedPhone",
    checked: false,
    disabled: false,
  },
  // {
  //   description: "Silent button not working",
  //   image: silentButton,
  //   name: "silentButton",
  //   checked: false,
  //   disabled: false,
  // },
];
export const physical_defects_mkopa = [
  {
    description: "Screen is broken",
    image: broken_screen,
    name: "brokenScreen",
    checked: false,
    disabled: false,
  },
  {
    description: "Camera glass scratched/broken",
    image: camera_glass,
    name: "cameraGlass",
    checked: false,
    disabled: false,
  },
  {
    description: "Battery has issues",
    image: battery,
    name: "battery",
    checked: false,
    disabled: false,
  },
  {
    description: "Phone has dents",
    image: phoneDented,
    name: "dentedPhone",
    checked: false,
    disabled: false,
  },
 
];
export const extras = [
  {
    description: "Original plug and cable",
    shortInfo:"Customer might get more value if they have the cable & charger",
    image: charger,
    name: "charger",
    checked: false,
    disabled: false,
  },
  {
    description: "Receipt with same IMEI present",
    shortInfo:"Customer might get more value if they have the receipt with same IMEI",
    image: receipt,
    name: "reciept",
    checked: false,
    disabled: false,
  },
  {
    description: "Warranty present",
    shortInfo:"Customer might get more value if their device is under warranty",
    image: warranty,
    name: "warranty",
    checked: false,
    disabled: false,
  },
  // {
  //   description: "Earphones present",
  //   image: earphones,
  //   name: "earphones",
  //   checked: false,
  //   disabled: false,
  // },

  // {
  //   description: "Stylus/Pen Available",
  //   image: stylus,
  //   name: "pen",
  //   checked: false,
  //   disabled: false,
  // },
  {
    description: "Original Box Available",
    image: stylus,
    name: "box",
    checked: false,
    disabled: false,
  },
  // {
  //   description: "Original charger cable of device present",
  //   image: cable,
  //   name: "cable",
  //   checked: false,
  //   disabled: false,
  // },
];

export const repair_pro_defects = [
  {
    description: "Phone is able to make and receive calls",
    image: volume_buttons,
    name: "workingPhone",
    checked: false,
    disabled: false,
  },
  {
    description: "Phone is able to switch on/off?",
    image: volume_buttons,
    name: "deviceOn",
    checked: false,
    disabled: false,
  },
  {
    description: "Volume buttons not working",
    image: volume_buttons,
    name: "volumeButtons",
    checked: false,
    disabled: false,
  },
  {
    description: "Fingerprint scanner not working",
    image: fingerprint_scanner,
    name: "fingerScanner",
    checked: false,
    disabled: false,
  },
  {
    description: "Wifi not working",
    image: wifi,
    name: "WiFi",
    checked: false,
    disabled: false,
  },
  {
    description: "Infrared not working",
    image: infrared,
    name: "infrared",
    checked: false,
    disabled: false,
  },
  {
    description: "SimCard Tray available",
    image: simcardTray,
    name: "simcardTray",
    checked: false,
    disabled: false,
  },
  {
    description: "Flashlight not working",
    image: flashLight,
    name: "flashlight",
    checked: false,
    disabled: false,
  },
  {
    description: "Face sensor not working",
    image: facesensor,
    name: "facesensor",
    checked: false,
    disabled: false,
  },
  {
    description: "Speakers not working",
    image: speakers,
    name: "speakers",
    checked: false,
    disabled: false,
  },
  {
    description: "Earpiece not working",
    image: earpiece,
    name: "earpiece",
    checked: false,
    disabled: false,
  },
  {
    description: "Power button not working",
    image: power_button,
    name: "powerButton",
    checked: false,
    disabled: false,
  },
  {
    description: "Charging port not working",
    image: charging_port,
    name: "chargingPort",
    checked: false,
    disabled: false,
  },
  {
    description: "Bluetooth not working",
    image: bluetooth,
    name: "bluetooth",
    checked: false,
    disabled: false,
  },
  {
    description: "Phone vibrator not working",
    image: phone_vibrator,
    name: "vibrator",
    checked: false,
    disabled: false,
  },
  {
    description: "Microphone not working",
    image: microphone,
    name: "microphone",
    checked: false,
    disabled: false,
  },
  {
    description: "Selfie camera not working",
    image: microphone,
    name: "selfieCamera",
    checked: false,
    disabled: false,
  },
  {
    description: "Main camera not working",
    image: microphone,
    name: "mainCamera",
    checked: false,
    disabled: false,
  },
  {
    description: "Silent button not working",
    image: silentButton,
    name: "silentButton",
    checked: false,
    disabled: false,
  },
  {
    description: "Screen is broken",
    image: broken_screen,
    name: "brokenScreen",
    checked: false,
    disabled: false,
  },
  {
    description: "Camera glass scratched/broken",
    image: camera_glass,
    name: "cameraGlass",
    checked: false,
    disabled: false,
  },
  {
    description: "Back glass/cover broken or scratched",
    image: backglass,
    name: "backglass",
    checked: false,
    disabled: false,
  },
  {
    description: "Battery has issues",
    image: battery,
    name: "battery",
    checked: false,
    disabled: false,
  },
  {
    description: "Phone has dents",
    image: phoneDented,
    name: "dentedPhone",
    checked: false,
    disabled: false,
  },
];
