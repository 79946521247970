import React, { useEffect } from "react";
import { ToastWrapper } from "./Toast.style";
import { useToastContext, REMOVE, REMOVE_ALL } from "context/ToastContext";

export default function Toast({ toast }) {
  const { toastDispatch } = useToastContext();

  useEffect(() => {
    setTimeout(function () {
      toastDispatch({
        type: REMOVE_ALL,
      });
    }, 5000);
  }, [toast]);

  return (
    <ToastWrapper>
      {toast.map((t) => {
        return (
          <div
            className={`toast-container-item border-${t.type ? t.type : ""}`}
            key={t.id}
          >
            <div
              className={`toast-header bg-${t.type ? t.type : ""} border-${
                t.type ? t.type : ""
              }`}
            >
              <span className="font-weight-semibold mr-auto text-uppercase">
                {t.type.toLowerCase() === "info" && "information!"}
                {t.type.toLowerCase() === "success" && "Success!"}
                {t.type.toLowerCase() === "danger" && "Error!"}
                {t.type.toLowerCase() === "warning" && "Warning!"}
              </span>

              <button
                type="button"
                className="ml-3 close text-white"
                data-dismiss="toast"
                onClick={() =>
                  toastDispatch({ type: REMOVE, payload: { id: t.id } })
                }
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="toast-body">{t.content}</div>
          </div>
        );
      })}
    </ToastWrapper>
  );
}
