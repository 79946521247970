import React from 'react'
import {Empty} from 'antd'

const EmptyComponent = ({description="No results found"}) => {
    return (
    <Empty description={description}/>
    )
}

export default EmptyComponent
