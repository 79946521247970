import { useState, useEffect, useCallback } from "react";
import { useToastContext, ADD } from "context/ToastContext";
import UserService from "services/UserService";
import { useAuth } from "context/AuthContext";

export const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { toastDispatch } = useToastContext();
    const {
      authState: { user },
    } = useAuth();

  const handleData = (callback) => {
    if (data) {
      callback(data);
      setData(null);
    }
  };

  const handleError = (callback) => {
    if (error) {
      callback(error);
      setError(null);
    }
  };
  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL + url}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          key: process.env.REACT_APP_KEY,
          secret: process.env.REACT_APP_SECRET,
          Country:
            UserService.getCurrentUserLoginData().country ||
            user?.country ||
            "1",
        },
      });
      const { success, msg, ...rest } = await res.json();
      if (success) {
        setData(rest);
        setLoading(false);
      } else {
        setLoading(false);
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
      }
    } catch (err) {
      setLoading(false);
      setError(err.message || "Unexpected Error!");
      toastDispatch({
        type: ADD,
        payload: {
          content: err.message || "Unexpected Error!",
          type: "danger",
        },
      });
    }
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [url]);

  return { data, handleData, loading, error, handleError, refetch: fetchData };
};
