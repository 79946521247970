import React, { useState } from "react";

import { useNavigate, Link, useLocation } from "react-router-dom";

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

export default function Login() {
  const { search } = useLocation();
  const urlParams = Object.fromEntries([...new URLSearchParams(search)]);

  const navigate = useNavigate();
  const { toastDispatch } = useToastContext();
  const { error, loading, sendHttpRequest: updatePassword } = useHttp();

  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  

  const resetPass = async (values) => {
    updatePassword(
      "admin_reset",
      "PUT",
      {
        email: urlParams.email,
        random: urlParams.random,
        password: values.password,
        role: urlParams.role,
        country: urlParams.country
      },
      ({ msg }) => {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
        navigate("/");
      }
    );
  };

  const ResetSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be atleast 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Confirm password is required"),
  });
  const showPassword = (event) => {
    let element =
      event.target.parentElement.parentElement.querySelector("input");
    if (element.type === "text") {
      element.type = "password";
      event.target.innerText = "Show";
    } else {
      element.type = "text";
      event.target.innerText = "Hide";
    }
  };

  return (
    <>
      <section>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="login-card">
                <Formik
                  initialValues={data}
                  validationSchema={ResetSchema}
                  onSubmit={(values) => {
                    resetPass(values);
                  }}
                >
                  {({ errors, touched }) => (
                    <Form className="theme-form login-form">
                      <h4 className="mb-3">Set new password</h4>
                      {/* <p>
                        Set your new password for signing in. <br />
                        Make sure you remember this password and keep it in a
                        safe place.
                      </p> */}

                      <div className="form-group mt-4">
                        <label>New Password</label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="icofont icofont-key"></i>
                          </span>
                          <Field
                            className="form-control"
                            type="password"
                            name="password"
                            placeholder="*********"
                          />
                          <div
                            className="show-hide"
                            onClick={(e) => showPassword(e)}
                          >
                            <span className="show"> Show </span>
                          </div>
                        </div>
                        {touched.password && errors.password && (
                          <small className="text-danger">
                            {errors.password}
                          </small>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="icofont icofont-key"></i>
                          </span>
                          <Field
                            className="form-control"
                            type="password"
                            name="confirmPassword"
                            placeholder="*********"
                          />
                          <div
                            className="show-hide"
                            onClick={(e) => showPassword(e)}
                          >
                            <span className="show"> Show </span>
                          </div>
                        </div>
                        {touched.confirmPassword && errors.confirmPassword && (
                          <small className="text-danger">
                            {errors.confirmPassword}
                          </small>
                        )}
                      </div>

                      <div className="form-group mt-5">
                        <button
                          className="btn btn-primary btn-block ld-ext-right text-capitalize"
                          type="submit"
                          disabled={loading}
                        >
                          Update Password
                          {loading && (
                            <>
                              <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </>
                          )}
                        </button>
                      </div>
                      <p className="text-center">
                        {" "}
                        <Link to="/">Back to Sign In</Link>
                      </p>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
