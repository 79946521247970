import React from "react";
import { Pagination } from "antd";

const PaginationComponent = ({
  data,
  handlePageChange,
  numEachPage,
  current,
}) => {
  return (
    <Pagination
      total={data.length}
      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
      size="small"
      onChange={handlePageChange}
      defaultPageSize={numEachPage}
      defaultCurrent={1}
      current={current}
    />
  );
};

export default PaginationComponent;
