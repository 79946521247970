import React from "react";
import { Menu, Badge } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export const StyledMenu = styled(Menu)`
  & .ant-menu-item::after {
    border-right: 3px solid rgb(88, 202, 185) !important;
  }
  & .ant-menu-item-selected {
    background-color: rgba(88, 202, 185, 0.12) !important;
  }
  & .ant-menu-item-selected,
  .ant-menu-item:hover {
    color: rgb(88, 202, 185);
  }
`;

const ListMenu = ({
  selectedKey,
  changeSelectedKey,
  sidebarLinks,
  country,
}) => {
  const navigate = useNavigate();

  const onMenuItemClick = (e, route) => {
    changeSelectedKey(e);
    navigate(`/${country + route}`);
  };

  return (
    <StyledMenu mode="inline" selectedKeys={[selectedKey]}>
      {sidebarLinks.map((link, i) => (
        <Menu.Item
          key={i}
          icon={<i className={`icofont ${link.icon}`} />}
          onClick={(e) => onMenuItemClick(e, link.route)}
        >
          {link.name}
        </Menu.Item>
      ))}
    </StyledMenu>
  );
};
export default ListMenu;
