// third party imports
import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import "antd/dist/antd.css";

// local imports
import "./App.css";

// Layout
// import TechnicianDashboard from "./pages/TechnicianDashboard";
// import RFODashboard from "./pages/RFODashboard";
// import AdminDashboard from "./pages/AdminDashboard";
// import MarketingDashboard from "./pages/MarketingDashboard";
// import CustomerCareDashboard from "./pages/CustomerCareDashboard";
// import QualityCheckDashboard from "./pages/QualityCheckDashboard";
import NoMatch from "app/auth/NoMatch";
import RequireAuth from "./RequireAuth";
// import B2B from "./pages/B2B";
// import AccountsDashboard from "./pages/AccountsDashboard";
// import ProcurementDashboard from "./pages/ProcurementDashboard";
// import ManagementDashboard from "./pages/ManagementDashboard";
// import ShopDashboard from "./pages/ShopDashboard";
// import RepairProDashboard from "./pages/RepairPro";
import UserService from "./services/UserService";
import Auth from "app/auth";
import { useAuth } from "context/AuthContext";
import { Loader } from "components/elements";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import ResetPassword from "app/auth/ResetPassword";
import ForgotPassword from "app/auth/ForgotPassword";
import BALogin from "app/auth/BALogin";
import DealerReciptLogin from "app/auth/dealerRecipt/DealerReciptLogin";
import MkopaLogin from "app/auth/MkopaLogin";
import BAForgotPassword from "app/auth/BAForgotPassword";
import MkopaForgotPassword from "app/auth/MkopaForgotPassword";
import RMLogin from "app/auth/RMLogin";
import RMForgotPassword from "app/auth/RMForgotPassword";
import SalesAgentDashboard from "pages/SalesAgentDashboard";
import SalesSupervisorDashboard from "pages/SalesSupervisorDashboard";

const TechnicianDashboard = lazy(() => import("./pages/TechnicianDashboard"));
const NewTechnicianDashboard = lazy(() =>
  import("./pages/NewTechnicianDashboard")
);
const DealerReciptDashboard = lazy(() =>
  import("./pages/DealerReciptDashboard")
);
const RFODashboard = lazy(() => import("./pages/RFODashboard"));
const AdminDashboard = lazy(() => import("./pages/AdminDashboard"));
const MarketingDashboard = lazy(() => import("./pages/MarketingDashboard"));
const CustomerCareDashboard = lazy(() =>
  import("./pages/CustomerCareDashboard")
);
const QualityCheckDashboard = lazy(() =>
  import("./pages/QualityCheckDashboard")
);
const B2B = lazy(() => import("./pages/B2B"));
const AccountsDashboard = lazy(() => import("./pages/AccountsDashboard"));
const ProcurementDashboard = lazy(() => import("./pages/ProcurementDashboard"));
const ManagementDashboard = lazy(() => import("./pages/ManagementDashboard"));
const InventoryDashboard = lazy(() => import("./pages/InventoryDashboard"));
const ShopDashboard = lazy(() => import("./pages/ShopDashboard"));
const RepairProDashboard = lazy(() => import("./pages/RepairPro"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 100,
    },
  },
});

const App = () => {
  const {
    authState: { user },
  } = useAuth();
  const country =
    UserService.getCurrentUserLoginData().country || user?.country;

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialOpen={false} />
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* Public Routes */}
          <Route path={`/`} exact element={<Auth country={country} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/ba-forgot-password" element={<BAForgotPassword />} />
          <Route path="/rm-forgot-password" element={<RMForgotPassword />} />
          <Route
            path="/mkopa-forgot-password"
            element={<MkopaForgotPassword />}
          />
          <Route path="/ba-login" element={<BALogin />} />
          <Route path="/rm-login" element={<RMLogin />} />
          <Route path="/partner-login" element={<DealerReciptLogin />} />
          <Route path="/mkopa-login" element={<MkopaLogin />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<NoMatch />} />
          {/* Protected Routes */}
          <Route
            path={`/${country}/rfo/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <RFODashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/dealer/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <DealerReciptDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/customer-care/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <CustomerCareDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/technician/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <TechnicianDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/new-technician/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <NewTechnicianDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/sales-agent/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <SalesAgentDashboard country={country} />{" "}
              </RequireAuth>
            }
          />{" "}
          <Route
            path={`/${country}/sales-supervisor/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <SalesSupervisorDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/admin/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <AdminDashboard country={country} />
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/quality-check/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <QualityCheckDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/marketing/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <MarketingDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/agent/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <B2B country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/accounts/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <AccountsDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/procurement/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <ProcurementDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/management/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <ManagementDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/inventory/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <InventoryDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/shop/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <ShopDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          <Route
            path={`/${country}/repair-pro/*`}
            element={
              <RequireAuth loggedIn={UserService.isLoggedIn}>
                <RepairProDashboard country={country} />{" "}
              </RequireAuth>
            }
          />
          {/* </Route> */}
        </Routes>
      </Suspense>
    </QueryClientProvider>
  );
};

export default App;
