import React from "react";
import { Breadcrumb } from "antd";
import styles from "./PageHeader.module.css";

const PageHeaderComponent = ({
  routes,
  title,
  icon,
  subtitle,
  extras = [],
}) => {
  return (
    <div className={styles["app-page-title"]}>
      <div className={styles["page-title-wrapper"]}>
        <div className={styles["page-title-heading"]}>
          {icon && (
            <div className={styles["page-title-icon"]}>
              <i className={`icofont ${icon}`} style={{ color: "#58cab9" }}></i>
            </div>
          )}
          <div>
            {title}
            {subtitle && <div className={styles["page-title-subheading"]}>{subtitle}</div>}
            {routes && (
              <Breadcrumb>
                {routes.map((route) => (
                  <Breadcrumb.Item href={route.path}>
                    {route.icon && (
                      <i className={`icofont ${route.icon} mr-1`} />
                    )}
                    <span>{route.breadcrumbName}</span>
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            )}
          </div>
        </div>
        <div className="ml-auto clearfix d-flex ">{extras}</div>
      </div>
    </div>
  );
};

export default PageHeaderComponent;
