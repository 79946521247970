import React from "react";
import { LoaderWrapper } from "./Loader.style.js";

export default function Loader({ text = "Just a moment..." }) {
  return (
    <>
      <LoaderWrapper>
        <div className="loader"> </div>
        <p>{text}</p>
      </LoaderWrapper>
    </>
  );
}
