import { useState ,useEffect } from "react";
import UserService from "services/UserService";
import { useToastContext, ADD } from "context/ToastContext";
import { useAuth } from "context/AuthContext";




export function useHttp() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { toastDispatch } = useToastContext();
  const {
    authState: { user },
  } = useAuth();

  let headers = {
    "Content-Type": "application/json",
    key: process.env.REACT_APP_KEY,
    secret: process.env.REACT_APP_SECRET,
    Country:
      UserService.getCurrentUserLoginData().country || user?.country || "1",
  };

  function sendHttpRequest(url, method, body, action, options) {
    setLoading(true);

    if (options) {
      for (const key in options) {
        if (key in headers) {
          if (key === "Content-Type") {
            delete headers[key];
          } else {
            headers[key] = options[key];
          }
        }
      }
    }

    fetch(`${process.env.REACT_APP_BASE_URL + url}`, {
      method,
      headers,
      body:
        body && !headers["Content-Type"]
          ? body
          : body && headers["Content-Type"]
          ? JSON.stringify(body)
          : null,
    })
      .then(async (res) => {
        // if (!res.ok) {
        //   setLoading(false);
        //   toastDispatch({
        //     type: ADD,
        //     payload: {
        //       content: "Unexpected Error!",
        //       type: "danger",
        //     },
        //   });
        // }

        const { success, msg, ...rest } = await res.json();
        if (success) {
          action({ success, data: rest, msg });
          setLoading(false);
        } else {
          setLoading(false);
          toastDispatch({
            type: ADD,
            payload: {
              content: msg || "Unexpected Error Occured. Try again!",
              type: "danger",
            },
          });
        }
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
        toastDispatch({
          type: ADD,
          payload: {
            content: error.message || "Server Error. Try again!",
            type: "danger",
          },
        });
      });
  }
  return { error, loading, sendHttpRequest };
}


export function useDebounce(value, timeout, callback) {
  const [timer, setTimer] = useState(null);

  const clearTimer = () => {
    if (timer) clearTimeout(timer);
  }

  useEffect(() => {
    clearTimer();

    if (value && callback) {
      const newTimer = setTimeout(callback, timeout);
      setTimer(newTimer);
    }
  }, [value]);
}
