import React, { useContext } from "react";
import { AppContext } from "context/AppContext";

export const withRole = (roles) => (Component) => (props) => {
  const { userRole } = useContext(AppContext);

  if (userRole.match(roles)) {
    return <Component {...props} />;
  }
  return null;
};
