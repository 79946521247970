import styled from "styled-components";

export const LoaderWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid rgb(88, 202, 185);
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
  p {
    font-weight: bold;
    color: rgb(88, 202, 185);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
