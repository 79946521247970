import React from "react";
import { Dashboard } from "layouts";
import { salesSupervisorRoutes } from "app/sales-supervisor/salesSupervisorRoutes";
import { salesSupervisorLinks } from "app/sales-supervisor/modules/general/landing/salesSupervisorLinks";

const SalesSupervisorDashboard = ({ country }) => {
  return (
    <Dashboard
      routes={salesSupervisorRoutes}
      name="Sales Supervisor Dashboard"
      sidebarLinks={salesSupervisorLinks}
      country={country}
    />
  );
};

export default SalesSupervisorDashboard;
