import React from "react";
import { Layout } from "antd";
import styled from "styled-components";

const StyledLayout = styled(Layout.Sider)`
  height: 100vh;
  /* margin-right: 24px; */
`;

const SideBar = ({ menu , isSideBarOpen}) => {
  return (<>
   {isSideBarOpen === true ? <StyledLayout
      breakpoint={"lg"}
      theme="light"
      collapsedWidth={0}
      trigger={null}
      width={280}
    >
      {menu}
    </StyledLayout>:""}
  </>
  );
};

export default SideBar;
