import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { dogBreeds } from "./data";
import styled, { keyframes } from "styled-components";
import styles from './SearchBar.module.css'

export const StyledSearchContainer = styled.div`
  z-index: 5000;
  /* font-size: 5vmin; */
  box-shadow: 0 0.5rem 1rem rgba(black, 0.1);
  border-radius: 0.5rem;
`;

export const StyledSearchLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.5em;
  background-color: #fff;
  border-bottom: 1px solid #EAECED);
  border-radius: .5rem;

   &:focus-within {
    box-shadow: 0 0 3px #92a4de;
  }
   & svg {
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
        stroke: #9297a8;
  }
 
`;

export const StyledSearchInput = styled.input`
  appearance: none;
  border: none;
  background: transparent;

  /* Because we're using focus-within above. Probably needs @supports */
  &:focus {
    outline: none;
  }
`;

export const StyledResultsContainer = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 50vh;
  width: 100%;
  z-index: -1;
  font-size: 0.6em;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  margin: 0 0;
  padding: 0;
  list-style-type: none;

  &:before {
    content: "";
    position: absolute;
    top: calc(0.5rem * -1);
    left: 0;
    width: 100%;
    background-color: #f7f9fb;

    height: calc((var(--height, 0) * 1px) + 0.5rem);
    transition: height 0.5s;
    border-radius: inherit;
  }
`;

const listAnimation = keyframes`
  from {
      transform: scale(0.8);
      opacity: 0;
    }
`;

export const StyledResultsItem = styled.li`
  margin: 0.25rem 0.5em;
  padding: 0.5em 0.6em;
  list-style-type: none;
  border-radius: 0.5rem;
  animation: ${listAnimation} 0.3s backwards;
  /* animation: pop-in 0.3s backwards; */
  animation-delay: calc(var(--i) * 0.08s);
  background-color: transparent;
  transition: all 0.1s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #333;

  &:before {
    content: "dog";
    text-transform: uppercase;
    font-size: 0.5rem;
    font-weight: bold;
    background: #6d7386;
    color: white;
    border-radius: 0.25em;
    margin-right: 0.5rem;
    display: inline-block;
    padding: 0.25em;
  }

  &:hover {
    background-color: #edeff2;
  }

  // transform: translateY(
  //   calc(
  //     (var(--dy, 0)) * 1px
  //   )
  // );
  // transition: transform .3s linear;

  /* @keyframes pop-in {
    from {
      transform: scale(0.8);
      opacity: 0;
    }
  } */
`;

// function SearchInput({ query, onChange }) {
//   return (
//     <StyledSearchLabel>
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="48"
//         height="48"
//         viewBox="0 0 24 24"
//         fill="none"
//         stroke="#000000"
//         stroke-width="3"
//         stroke-linecap="round"
//         stroke-linejoin="round"
//       >
//         <circle cx="11" cy="11" r="8" />
//         <line x1="21" y1="21" x2="16.65" y2="16.65" />
//       </svg>

//       <StyledSearchInput
//         placeholder="Search for dog breeds"
//         type="search"
//         defaultValue={query}
//         onChange={(event) => {
//           onChange(event.target.value);
//         }}
//       />
//     </StyledSearchLabel>
//   );
// }

// function Result({ children, style }) {
//   const elRef = useRef(null);
//   const topRef = useRef(0);

//   // TODO: Get dy working
//   const [top, setTop] = useState(null);
//   const [dy, setDy] = useState(0);

//   return (
//     <StyledResultsItem
//       style={{
//         ...style,
//         "--dy": dy,
//       }}
//       ref={elRef}
//     >
//       {children}
//     </StyledResultsItem>
//   );
// }

// function Results({ data = [] }) {
//   const elRef = useRef(null);

//   const [height, setHeight] = useState(null);
//   useLayoutEffect(() => {
//     if (!elRef.current) return;
//     const rect = elRef.current.getBoundingClientRect();
//     setHeight(rect.height);
//   });

//   return (
//     <StyledResultsContainer
//       ref={elRef}
//       style={{ "--height": height }}
//       data-height={height}
//     >
//       {data.map((item, i) => {
//         return (
//           <Result
//             key={item}
//             style={{
//               "--i": i,
//             }}
//           >
//             {item}
//           </Result>
//         );
//       })}
//     </StyledResultsContainer>
//   );
// }

function SearchInput({ query, onChange }) {
  return (
    <label className={styles["search"]}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        strokeWidth="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="11" cy="11" r="8" />
        <line x1="21" y1="21" x2="16.65" y2="16.65" />
      </svg>

      <input
        className={styles["search-input"]}
        placeholder="Search here..."
        type="search"
        defaultValue={query}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      />
    </label>
  );
}

function Result({ children, style }) {
  const elRef = useRef(null);
  const topRef = useRef(0);

  // TODO: Get dy working
  const [top, setTop] = useState(null);
  const [dy, setDy] = useState(0);

  //   useEffect(() => {
  //     if (!elRef.current) return;

  //     const nextTop = elRef.current.getBoundingClientRect().top;

  //     if (nextTop === topRef.current) return;

  //     const deltaY = nextTop - topRef.current;

  //     topRef.current = nextTop;

  //     setDy(deltaY);

  //     requestAnimationFrame(() => {
  //       setDy(0);
  //     });
  //   });

  return (
    <li
      style={{
        ...style,
        "--dy": dy,
      }}
      className={styles["result-item"]}
      ref={elRef}
    >
      {children}
    </li>
  );
}

function Results({ data = [] }) {
  const elRef = useRef(null);

  const [height, setHeight] = useState(null);
  useLayoutEffect(() => {
    if (!elRef.current) return;
    const rect = elRef.current.getBoundingClientRect();
    setHeight(rect.height);
  });

  return (
    <ul
      className={styles["results"]}
      ref={elRef}
      style={{ "--height": height }}
      data-height={height}
    >
      {data.map((item, i) => {
        return (
          <Result
            key={item}
            style={{
              "--i": i,
            }}
          >
            {item}
          </Result>
        );
      })}
    </ul>
  );
}

const SearchBar = () => {
  const [query, setQuery] = useState("")
  const [showSearchbar, setShowSearchbar] = useState(true)
  useEffect(() => {
    let currentPath = window.location.pathname?.split("/")?.pop();
    if(currentPath === "mkopa"||currentPath === "buy-phone"){
      setShowSearchbar(false)
    }
  }, []);
  const results =
    query.length < 2
      ? []
      : dogBreeds
          .filter((dogBreed) => {
            return dogBreed.includes(query);
          })
          .slice(0, 5);

  return (
    // <StyledSearchContainer>
    //   <SearchInput onChange={setQuery} query={query} />
    //   <Results data={results} />
    // </StyledSearchContainer>
    <>
    {
      showSearchbar? <div className={styles["searchbar"]}>
      <SearchInput onChange={setQuery} query={query} />
      <Results data={results} />
    </div>: ""
    }
   </>
  );
};

export default SearchBar;
