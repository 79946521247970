import React, { PureComponent } from "react";
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from "recharts";

const data = [
  { name: "Operation Costs", value: 400 },
  { name: "Buyback", value: 300 },
  { name: "Sales", value: 300 },
  { name: "Salaries & Wages", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export default class DonutWithPadding extends PureComponent {
  render() {
    return (
      <PieChart width={500} height={200} onMouseEnter={this.onPieEnter}>
        <Pie
          data={data}
          cx={120}
          cy={100}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend layout="vertical" align="right" height={150} />
        {/* <Pie
              data={data}
              cx={420}
              cy={200}
              startAngle={180}
              endAngle={0}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie> */}
      </PieChart>
    );
  }
}
