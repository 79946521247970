import React, { useState, useContext } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useFetch, useHttp } from "hooks";
import { useToastContext, ADD } from "context/ToastContext";
import { AppContext } from "context/AppContext";
import { useAuth } from "context/AuthContext";

export default function MkopaLogin() {
  const navigate = useNavigate();
  const { setUserRole } = useContext(AppContext);
  const { toastDispatch } = useToastContext();
  const { setAuthState, user } = useAuth();
  const { error, loading, sendHttpRequest: MkopaLoginRequest } = useHttp();
  const { state } = useLocation();
  const [credentials, setCredentials] = useState({
    email: "",
    permission: "",
    country: "",
    password: "",
  });

  const showPassword = (event) => {
    let element =
      event.target.parentElement.parentElement.querySelector("input");
    if (element.type === "text") {
      element.type = "password";
      event.target.innerText = "Show";
    } else {
      element.type = "text";
      event.target.innerText = "Hide";
    }
  };

  const onChange = ({ target: { name, value } }) => {
    setCredentials({ ...credentials, [name]: value });
  };

  const requestReset = async (e) => {
    sessionStorage.clear();
    e.preventDefault();

    let _data = {
      auth: credentials.email,
      password: credentials.password,
      permission: 14,
      country: credentials.country,
    };

    MkopaLoginRequest(
      "login_ba_mkopa",
      "POST",
      _data,
      ({ data: { token, user } }) => {
        const { lname, fname, role, id, AssignedDealer, country } = user;
        let cntry = country?.toLowerCase();
        setUserRole(user);
        window.sessionStorage.setItem(
          "loginData",
          JSON.stringify({
            token,
            role,
            id,
            lname,
            fname,
            AssignedDealer,
            country: cntry,
          })
        );

        setAuthState((prevState) => ({
          ...prevState,
          user,
          isAuthenticated: true,
        }));
        navigate(`/${cntry}/agent/mkopa`);
        // navigate(
        //   renderDashboard(role?.permission, country?.toLowerCase()) ||
        //     state?.path
        // );
        // setUseLogin({ ...useLogin, otp: "" });
      }
    );
  };
  // function renderDashboard(param, country) {
  //   switch (param) {
  //     case 1:
  //       return `/${country}/agent/buy-phone`;
  //     default:
  //     // return "/";
  //   }
  // }
  return (
    <>
      <section>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="login-card">
                <form
                  className="theme-form login-form"
                  method="post"
                  onSubmit={requestReset}
                >
                  <h4>Mkopa Login</h4>
                  <h6>Welcome back! Log in to your account.</h6>
                  <div className="form-group">
                    <label>Select your Country</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="icofont icofont-flag"></i>
                      </span>
                      <select
                        className="form-control"
                        name="country"
                        placeholder="Select a country"
                        onChange={onChange}
                        required
                      >
                        <option value="">Select a country</option>
                        <option value="ke">
                          {" "}
                          {"\uD83C\uDDF0\uD83C\uDDEA"} Kenya
                        </option>
                        <option value="ug">
                          {"\uD83C\uDDFA\uD83C\uDDEC"} Uganda
                        </option>
                        <option value="tz">
                          {"\u{1f1f9}\u{1f1ff}"} Tanzania
                        </option>
                      </select>
                    </div>
                  </div>
                  
                  <div className="form-group">
                    <label>Email Address</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="icofont icofont-envelope"></i>
                      </span>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="your email"
                        onChange={onChange}
                        required
                        disabled={!credentials.country}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="icofont icofont-key"></i>
                      </span>
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="*********"
                        onChange={onChange}
                        required
                        disabled={!credentials.country}
                      />
                      <div
                        className="show-hide"
                        onClick={(e) => showPassword(e)}
                      >
                        <span className="show"> Show </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="checkbox">
                      <input id="checkbox1" type="checkbox" />
                      <label htmlFor="checkbox1">
                        <small>Remember password</small>
                      </label>
                    </div>
                  </div>

                  <p className="text-right">
                    <Link className="link " to="/mkopa-forgot-password">
                      Forgot password?
                    </Link>
                  </p>
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block ld-ext-right"
                      type="submit"
                      disabled={loading}
                    >
                      Login
                      {/* {isBaAccount(credentials.permission)
                          ? "Login"
                          : "Request OTP"} */}
                      {loading && (
                        <>
                          <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </>
                      )}
                    </button>
                  </div>
                  {/* <div className="form-group mt-5">
                    <button
                      className="btn btn-primary btn-block ld-ext-right text-capitalize"
                      type="submit"
                      // onClick={() => navigate("/reset-password")}
                      disabled={loading}
                    >
                      Request Password Reset
                      {loading && (
                        <>
                          <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </>
                      )}
                    </button>
                  </div>
                  <p className="text-center">
                    {" "}
                    <Link to="/">Back to Sign In</Link>
                  </p> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
