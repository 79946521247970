import {useState } from "react";

export const useMapQuestions = (defects) => {
 
  const [physicalDefects, setPhysicalDefects] = useState(null);
  const [functionalDefects, setFunctionalDefects] = useState(null);
  const [extras, setExtras] = useState(null);

  const handlePhysicalDefectsData = (callback) => {
    if (physicalDefects) {
      callback(physicalDefects);
      setPhysicalDefects(null);
    }
  };

  const handleFunctionalDefectsData = (callback) => {
    if (functionalDefects) {
      callback(functionalDefects);
      setFunctionalDefects(null);
    }
  };
  const handleExtrasData = (callback) => {
    if (extras) {
      callback(extras);
      setExtras(null);
    }
  };

  const mapPhysicalDefects = (data) => {
    if (!data) return;

    let p_Defects = [];
    // map physical defects
    Object.entries(data).map(([key, value]) => {
      defects.map((item) => {
        const { description, image, name } = item;
        if (key === name) {
          let tempItem = {
            description,
            image,
            checked: value >= 1,
            name,
          };
          p_Defects.push(tempItem);
        }
      });
    });

    setPhysicalDefects(p_Defects.filter((el) => el.checked));
  };

  const mapFunctionalDefects = (data) => {
   
    let f_Defects = [];
    // map functional defects
    Object.entries(data).map(([key, value]) => {
      defects.map((item) => {
        const { description, image, name } = item;
        if (key === name) {
          let tempItem = {
            description,
            image,
            checked: value >= 1,
            name,
          };
          f_Defects.push(tempItem);
        }
      });
    });
    setFunctionalDefects(
      f_Defects.filter(
        (el) =>
          (el.checked && el.name !== "workingPhone") ||
          (!el.checked && el.name === "workingPhone")
      )
    );
  }

  const mapExtras = (data) => {
    
    let extras_included = [];
    // map extras
    Object.entries(data).map(([key, value]) => {
    defects.map((item) => {
        const { description, image, name } = item;

        if (key === name) {
          let tempItem = {
            description,
            image,
            checked: value === 0,
            name,
          };
          extras_included.push(tempItem);
        }
      });
    });
    setExtras(extras_included.filter((el) => !el.checked));
  }

  return {
    mapPhysicalDefects,
    mapFunctionalDefects,
    mapExtras,
    physicalDefects,
    handlePhysicalDefectsData,
    handleFunctionalDefectsData,
    handleExtrasData,
  };
};
