import { useEffect, useState } from "react";
import { useMapQuestions } from "hooks";
import {
  functional_defects,
  physical_defects,
  extras,
} from "components/elements/phoneDefects/defects";

export const useMapDefects = () => {
  const [data, setData] = useState(null);
  const [physicalDefects, setPhysicalDefects] = useState(null);
  const [functionalDefects, setFunctionalDefects] = useState(null);
  const [ext, setExt] = useState(null);
  const { handlePhysicalDefectsData, mapPhysicalDefects } =
    useMapQuestions(physical_defects);
  const { handleFunctionalDefectsData, mapFunctionalDefects } =
    useMapQuestions(functional_defects);
  const { handleExtrasData, mapExtras } = useMapQuestions(extras);

  useEffect(() => {
    if (!data) return;
    mapPhysicalDefects(data);
    mapFunctionalDefects(data);
    mapExtras(data);
  }, [data]);

  handlePhysicalDefectsData((data) => setPhysicalDefects(data));
  handleFunctionalDefectsData((data) => setFunctionalDefects(data));
  handleExtrasData((data) => setExt(data));

  return [
    {
      physicalDefects,
      functionalDefects,
      ext,
    },
    setData,
  ];
};
