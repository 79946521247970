import { format } from "date-fns";
import { withRole } from "hoc/withRole";
import { Badge } from "antd";
import * as d3 from "d3";

export function numberWithCommas(x) {
  if (x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export function dateIsValid(date) {
  return !Number.isNaN(new Date(date).getTime());
}
export function formatDate(dateInputFormat) {
  if (dateIsValid(dateInputFormat)) {
    return format(new Date(dateInputFormat), "dd-MMM-yyyy");
  } else {
    // 👇️ this runs
    console.error("not a valid date");
  }
}
export function formatTime(timeInputFormat) {
  if (timeInputFormat) {
    return format(new Date(timeInputFormat), "HH:mm a");
  }
}

export function fileExtension(file_url) {
  if (file_url) {
    let extension = file_url.split(".").pop();
    return extension;
  }
}

export function formatCurrency(value, currency = "KES") {
  if (value !== undefined || value !== null) {
    const nf = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return nf.format(value);
  }
}

export function greeting() {
  let myDate = new Date();
  let hrs = myDate.getHours();

  if (hrs < 12) return "Good Morning";
  else if (hrs >= 12 && hrs <= 17) return "Good Afternoon";
  else if (hrs >= 17 && hrs <= 24) return "Good Evening";
}

export function renderTag(status = {}) {
  const { name, id } = status;
  if (id) {
    switch (id) {
      case "CALCULATION_REQUESTED":
        return <Badge status="warning" size="large" text={name} />;
      case "PAYMENT_REQUESTED":
        return <Badge status="processing" size="large" text={name} />;
      case "CUSTOMER_CARE_REVIEW":
        return <Badge status="processing" size="large" text={name} />;
      case "ASSIGNED_TECHNICIAN":
        return <Badge status="success" size="large" text={name} />;
      case "RFO_APPROVED":
        return <Badge status="success" size="large" text={name} />;
      case "RFO_REJECTED":
        return <Badge status="error" size="large" text={name} />;
      case "QA_REVIEW":
        return <Badge status="processing" size="large" text={name} />;
      case "QA_APPROVED":
        return <Badge status="success" size="large" text={name} />;
      case "QA_REJECTED":
        return <Badge status="error" size="large" text={name} />;
      case "REPAIR_REQUESTED":
        return <Badge status="processing" size="large" text={name} />;
      case "REPAIR_COMPLETE":
        return <Badge status="success" size="large" text={name} />;
      case "REPAIR_PENDING":
        return <Badge status="warning" size="large" text={name} />;
      case "REPAIR_IN_PROGRESS":
        return <Badge status="processing" size="large" text={name} />;
      case "QC_REQUESTED":
        return <Badge status="processing" size="large" text={name} />;
      case "QA_AFTER_REFURB_REQUESTED":
        return <Badge status="processing" size="large" text={name} />;
      case "PACKAGING_REQUESTED":
        return <Badge status="processing" size="large" text={name} />;
      case "PACKAGING_COMPLETED":
        return <Badge status="success" size="large" text={name} />;
      case "READY_TO_SELL":
        return <Badge status="success" size="large" text={name} />;
      case "TECHNICIAN_APPROVED":
        return <Badge status="success" size="large" text={name} />;
      case "TECHNICIAN_REJECTED":
        return <Badge status="error" size="large" text={name} />;
      case "TECHNICIAN_QA_REQUESTED":
        return <Badge status="processing" size="large" text={name} />;
      case "QC_COMPLETED":
        return <Badge status="success" size="large" text={name} />;
      case "TECHNICIAN_READY":
        return <Badge status="processing" size="large" text={name} />;
      case "DISBURSMENT_REQUESTED":
        return <Badge status="processing" size="large" text={name} />;
      case "DISBURSMENT_REJECTED":
        return <Badge status="error" size="large" text={name} />;
      case "DISBURSMENT_CANCELLED":
        return <Badge status="error" size="large" text={name} />;
      case "DISBURSMENT_APPROVED":
        return <Badge status="success" size="large" text={name} />;
      case "DISBURSMENT_PENDING":
        return <Badge status="warning" size="large" text={name} />;
      case "DISBURSMENT_COMPLETE":
        return <Badge status="success" size="large" text={name} />;
      default:
        return <Badge status="default" size="large" text={name} />;
    }
  } else {
    return <Badge status="default" size="large" text="Status unavailable" />;
  }
}

export function renderDoc(extension) {
  switch (extension) {
    case "pdf":
      return (
        <i
          className="icofont icofont-file-pdf icofont-4x "
          style={{ color: "#F40F02" }}
        />
      );
    case "xls":
      return (
        <i
          className="icofont icofont-file-excel icofont-4x "
          style={{ color: "#217346" }}
        />
      );
    case "xlsx":
      return (
        <i
          className="icofont icofont-file-excel icofont-4x "
          style={{ color: "#217346" }}
        />
      );
    case "doc":
      return (
        <i
          className="icofont icofont-file-word icofont-4x "
          style={{ color: "#2b579a" }}
        />
      );
    case "docx":
      return (
        <i
          className="icofont icofont-file-word icofont-4x "
          style={{ color: "#2b579a" }}
        />
      );

    default:
      return;
  }
}
const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  tabletL: "1000px",
  laptop: "1224px",
  laptopL: "1440px",
  desktop: "1800px",
  desktopL: "1400px",
};
export const devices = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  tabletL: `(min-width: ${sizes.tabletL})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
  desktopL: `(min-width: ${sizes.desktopL})`,
};

// role helpers
export const withAdmin = withRole(1);
export const withTechnician = withRole(2);
export const withCustomerCare = withRole(3);
export const withRfo = withRole(4);

export function getTimeStamp() {
  let d = new Date();
  let dformat = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}:${d.getMilliseconds()}`;
  return `${dformat}`;
}

export function appendToFilename(filename, string) {
  var dotIndex = filename.lastIndexOf(".");
  if (dotIndex === -1) return filename + string;
  else
    return (
      filename.substring(0, dotIndex) +
      "-" +
      string +
      filename.substring(dotIndex)
    );
}

export function responsivefy(svg) {
  // container will be the DOM element
  // that the svg is appended to
  // we then measure the container
  // and find its aspect ratio

  const container = d3.select(svg.node().parentNode),
    width = parseInt(svg.style("width"), 10),
    height = parseInt(svg.style("height"), 10),
    aspect = width / height;

  // set viewBox attribute to the initial size
  // control scaling with preserveAspectRatio
  // resize svg on inital page load
  svg
    .attr("viewBox", `0 0 ${width} ${height}`)
    .attr("preserveAspectRatio", "xMinYMid")
    .call(resize);

  // add a listener so the chart will be resized
  // when the window resizes
  // multiple listeners for the same event type
  // requires a namespace, i.e., 'click.foo'
  // api docs: https://goo.gl/F3ZCFr
  d3.select(window).on("resize." + container.attr("id"), resize);

  // this is the code that resizes the chart
  // it will be called on load
  // and in response to window resizes
  // gets the width of the container
  // and resizes the svg to fill it
  // while maintaining a consistent aspect ratio
  function resize() {
    const w = parseInt(container.style("width"));
    svg.attr("width", w);
    svg.attr("height", Math.round(w / aspect));
  }
}
