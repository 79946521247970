export const salesAgentLinks = [
  {
    name: "Sell In",
    icon: "icofont-home",
    route: "/sales-agent/dashboard",
  },
  {
    name: " Sell Out History",
    icon: "icofont-chart-histogram",
    route: "/sales-agent/sell-out",
  },
];