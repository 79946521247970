import React from "react";
import { Result } from "antd";

const ResultComponent = ({
  status = "success",
  title = "",
  subtitle = "",
  extra = [],
}) => {
  return (
    <Result status={status} title={title} subTitle={subtitle} extra={extra} />
  );
};

export default ResultComponent;
