import React from "react";
import { Breadcrumb } from "antd";

const BreadCrumbsComponent = ({ routes }) => {
  return (
    <Breadcrumb className="my-2">
      {/* <Breadcrumb.Item href="/dashboard">
        <i className="icofont icofont-home" /> Home
      </Breadcrumb.Item> */}
      {routes.map((el, i) => (
        <Breadcrumb.Item href={el.route} key={i}>
          {el.icon && <i className={`icofont ${el.icon}`} />}

          <span style={{ paddingLeft: "0.3rem" }}>{el.name}</span>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadCrumbsComponent;
