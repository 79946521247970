import React, { useState, useContext } from "react";
import { Upload, message, Modal, Form, Row, Col, Button, Input } from "antd";
import { AppContext } from "context/AppContext";
import Compressor from "compressorjs";

const ImageUploader = ({
  showUploads = false,
  type = "upload",
  description = "",
  onChange,
  name = "",
  listType = "picture-card",
  accept=""
}) => {
  const { url, setNewUrl } = useContext(AppContext);
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    loading: false,
    fileList: [],
  });
  // const [url, setNewUrl] = useState("");
  const { Dragger } = Upload;

  const { fileList, previewVisible, previewImage, previewTitle } = state;
  const triggerChange = (changedValue) => {
    onChange(changedValue);
  };
  function getBase64ForUpload(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     message.error("Image must smaller than 2MB!");
    //   }
    //   return isJpgOrPng && isLt2M;
  }
  const handleCancel = () => setState({ ...state, previewVisible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  const handleChange = (info, name) => {
    if (info.file.status === "uploading") {
      setState({ ...state, loading: true });
      return;
    }
    if (info.file.status === "done") {
     

      getBase64ForUpload(info.file.originFileObj, (imageUrl) => {
        new Compressor(info.file.originFileObj, {
          quality: 0.8,
          success: (compressedResult) => {
            setNewUrl({
              ...url,
              [name]: {
                file: new File(
                  [compressedResult],
                  `${
                    new Date().getTime() +
                    "." +
                    compressedResult.name.split(".").pop()
                  }`,
                  {
                    type: `image/${compressedResult.name.split(".").pop()}`,
                  }
                ),
                url: imageUrl,
              },
            });
          },
        });

        setState({ ...state, loading: false, fileList: info.fileList });
      });
    }
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
      triggerChange(file);
    }, 0);
  };
  const props = {
    name: "file",
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setNewUrl({
          ...url,
          [name]: { file: info.file.originFileObj },
        });
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  let d = new Date();
  let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

  return (
    <>
      {type === "upload" && (
        <>
          <Upload
            name={name + "-" + dformat}
            listType={listType}
            className="avatar-uploader"
            maxCount={1}
            onPreview={handlePreview}
            showUploadList={showUploads}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            defaultFileList={state.fileList}
            onChange={(info) => handleChange(info, name)}
            accept={accept}
          >
            {url[name] && listType === "picture-card" ? (
              <img
                src={url[name].url}
                alt="avatar"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            ) : !url[name] && listType === "picture-card" ? (
              <div>
                <i className="icofont icofont-image"></i>
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            ) : (
              <Button icon={<i className="icofont icofont-upload mr-2" />}>
                Upload
              </Button>
            )}
          </Upload>
          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
          {description && <small className="d-block mt-2">{description}</small>}
        </>
      )}
      {type === "dragger" && (
        <Dragger {...props} customRequest={dummyRequest}>
          <p className="ant-upload-drag-icon"></p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            {`Support for a single or bulk upload. ${description}`}
          </p>
        </Dragger>
      )}
    </>
  );
};

export default ImageUploader;
