import React, { useEffect, useState } from "react";
import { ListView } from "layouts";
import { PageHeaderComponent, Loader, Table } from "components/elements";
import { useHttp } from "hooks";
import ModalService from "components/elements/modal/ModalService";
import ReasignModal from "./ReasignModal";
import { Menu, Dropdown, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";

const Agent = () => {
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalProducts: 0,
  });
  const {
    error: errorInventory,
    loading: loadingInventory,
    sendHttpRequest: getInventory,
  } = useHttp();
  const {
    wareError,
    loading: wareLoading,
    sendHttpRequest: wareHouseGetAll,
  } = useHttp();
  const [wareHouseData, setWareHoseData] = useState([]);
  const ReasignWarehouseModal = (id) => {
    ModalService.open(ReasignModal, {
      id,
      wareHouseData,
      refresh: getInven,
    });
  };

  useEffect(() => {
    setPageData((prevState) => ({
      ...prevState,
      rowData: [],
      isLoading: true,
    }));
    getInven();
    wareHouseGetAll(`get_warehouses`, "GET", null, wareHandleData);
  }, []);

  const wareHandleData = ({ data: { warehouses } }) => {
    const formattedWarehouses = warehouses?.map((warehouse) => ({
      value: warehouse?._id,
      label: warehouse?.name,
    }));
    setWareHoseData(formattedWarehouses);
  };

  const handlInventoryData = ({ data: { find } }) => {
    let tempData = [];
    find?.map((el) => {
      return tempData.push({
        _id: el?._id,
        fname: el?.fname || "-",
        lname: el?.lname || "-",
        phone: el?.phone || "-",
        partner: el?.partner.length ? el?.partner[0].name : "-",
      });
    });
    setPageData({
      isLoading: false,
      rowData: tempData,
      totalProducts: find.length,
    });
  };

  const getInven = async () => {
    getInventory(`get_channel_agents`, "GET", null, handlInventoryData);
  };

  const columns = [
    {
      label: "ACTION",
      name: "_id",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="list-icons">
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="6"
                        onClick={() => ReasignWarehouseModal(value)}
                      >
                        Reasign Warehouse
                      </Menu.Item>
                      {/* <Menu.Item
                        key="7"
                        onClick={() =>
                          openReturnDeviceModal(value[0], value[4])
                        }
                      >
                        Return Device
                      </Menu.Item> */}
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <Button
                    className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                    icon={<MenuOutlined />}
                  />
                </Dropdown>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "First Name",
      name: "fname",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "Last Name",
      name: "lname",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "Phone",
      name: "phone",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
    {
      label: "Warehouse",
      name: "partner",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
  ];

  return (
    <>
      {loadingInventory && <Loader />}

      {!loadingInventory && (
        <ListView
          pageHeader={
            <PageHeaderComponent
              title="Agents"
              subtitle=""
              icon="icofont-users"
              extras={
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <div className="mb-3 mb-md-0">
                      <div className="ml-3 text-center">
                        <h3 className="font-weight-semibold mb-0">
                          {pageData?.totalProducts}
                        </h3>
                        <span>Available Agents</span>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              }
            />
          }
          table={
            <Table title={"Agents"} data={pageData.rowData} columns={columns} />
          }
          data={pageData.rowData}
        />
      )}
    </>
  );
};

export default Agent;
