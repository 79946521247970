import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import NoMatch from "./NoMatch";

function renderDashboard(param, country) {
  switch (param) {
    // case 12:
    //   return `/${country}/shop/agent`;
    case 20:
      return `/${country}/sales-supervisor/dashboard`;
    case 18:
        return `/${country}/sales-agent/dashboard`;
    case 2:
      return `/${country}/new-technician/repair-pendings`;
    case 17:
      return `/${country}/inventory/inv-rfq-stocks`;
    case 14:
      return `/${country}/agent/mkopa`;
    case 12:
      return `/${country}/repair-pro/agent`;
    case 11:
      return `/${country}/management/dashboard`;
    case 10:
      return `/${country}/accounts/dashboard`;
    case 9:
      return `/${country}/procurement/outbound`;
    case 8:
      return `/${country}/agent/buy-phone`;
    case 7:
      return `/${country}/marketing/dashboard`;
    case 6:
    //  return `/${country}/quality-check/dashboard`;
      return `/${country}/quality-check/pre-qc`;
    case 4:
      return `/${country}/rfo/dashboard`;
    case 3:
      return `/${country}/customer-care/dashboard`;
    case 16:
      return `/${country}/technician/dashboard`;
    case 1:
      return `/${country}/admin/dashboard`;
    default:
      return "/";
  }
}

const Auth = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    authState: { user },
  } = useAuth();

  if (user) {
    navigate(
      renderDashboard(user?.role?.permission, user?.country) || state?.path
    );
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
};

export default Auth;
